import {
  BusinessNodeEnum,
  BusinessNodeProps,
  NodeRaw,
  NodeTypeEnum,
  switchNodeMetadataType,
} from '../../types';
import { Edge, Node } from 'reactflow';
import {
  deleteBaseNode,
  deleteIfNode,
  deleteIfV2Node,
  deleteLoopNode,
  deleteSwitchNode,
} from 'src/FlowEngine2/Operations/deleteOperations';

import { ROOT_ID } from 'src/FlowEngine2/constant';
import { SecretsFormDataType } from '@comet/pages/Project/Secrets/Types';
import { json } from 'stream/consumers';
import { useFlowEngineStore } from '..';

// Bring all store fns here that you need.

// return edgeId
// logic is {SourceNodeId-TargetNodeId}
export function getEdgeId(
  sourceNodeId: string | null,
  targetNodeId: string | null
) {
  if (!sourceNodeId || !targetNodeId) return null;
  const { edgeIdMap } = useFlowEngineStore.getState();

  const edgeSearchKey = `${sourceNodeId}-${targetNodeId}`;
  if (!edgeIdMap.has(edgeSearchKey)) return null;

  return edgeIdMap.get(edgeSearchKey)!;
}

export function getNodeById(nodeId: string | null): NodeRaw | null {
  const { allNodesMap } = useFlowEngineStore.getState();

  if (!nodeId) return null;
  if (!allNodesMap.has(nodeId)) return null;

  return allNodesMap.get(nodeId)!;
}

export function getCurrentRoot() {
  const { rootStack } = useFlowEngineStore.getState();

  return rootStack[0];
}

export function getEdgeById(edgeId: string | null): Edge | null {
  if (!edgeId) return null;

  const { allEdgesMap } = useFlowEngineStore.getState();
  if (!allEdgesMap.has(edgeId)) return null;

  return allEdgesMap.get(edgeId)!;
}

// return array of ids
export function getNodesAttachedWithSourceHandle(nodeId: string | null) {
  if (!nodeId) return null;
  const { allEdges } = useFlowEngineStore.getState();

  let attachedNodes: string[] | null = null;
  for (const edge of allEdges) {
    if (edge.source === nodeId) {
      if (attachedNodes === null) attachedNodes = [];
      attachedNodes.push(edge.target);
    }
  }
  return attachedNodes;
}

// return array of ids
export function getNodesAttachedWithTargetHandle(nodeId: string | null) {
  if (!nodeId) return null;

  const { allEdges } = useFlowEngineStore.getState();
  let attachedNodes: string[] | null = null;

  for (const edge of allEdges) {
    if (edge.target === nodeId) {
      if (attachedNodes === null) attachedNodes = [];
      attachedNodes.push(edge.source);
    }
  }
  return attachedNodes;
}

export function getNodesUnderNode(nodeId: string | null): string[] {
  const nodeIdSet = new Set();

  function helper(nodeId: string | null) {
    if (!nodeId || nodeIdSet.has(nodeId)) return [];
    nodeIdSet.add(nodeId);

    const nodes: string[] = [nodeId];
    const edges: string[] = [];

    const attachedNodesId = getNodesAttachedWithSourceHandle(nodeId);
    attachedNodesId?.forEach((attachedNodeId) => {
      const attachedNode = getNodeById(attachedNodeId)!;

      edges.push(getEdgeId(nodeId, attachedNodeId)!);

      const nodesUnderAttachedNode = helper(attachedNodeId);
      nodesUnderAttachedNode.forEach((node) => nodes.push(node));

      if (
        [NodeTypeEnum.ExecutorNode, NodeTypeEnum.LoopNode].includes(
          attachedNode.type
        )
      ) {
        const executorRootNodeId = attachedNode.data.metadata.rootNode;
        if (executorRootNodeId) {
          const nodesInsideExecutorNode = helper(executorRootNodeId);
          nodesInsideExecutorNode.forEach((node) => nodes.push(node));
        }
      }
    });

    return nodes;
  }

  return helper(nodeId);
}

export function removeEdgesOfNodes(nodeIds: string[] | null) {
  if (!nodeIds) return null;

  const nodesSet = new Set();
  nodeIds.forEach((nodeId) => nodesSet.add(nodeId));

  const { allEdges, removeEdges } = useFlowEngineStore.getState();
  const deletedEdges = allEdges
    .filter(
      (edge: Edge) => nodesSet.has(edge.source) || nodesSet.has(edge.target)
    )
    .map((edge) => edge.id);

  removeEdges(deletedEdges);
}

export function getReactFlowEdge(edge: Edge): Edge | null {
  if (!edge.source || !edge.target) return null;

  const sourceNode = getNodeById(edge.source);
  const targetNode = getNodeById(edge.target);

  if (!sourceNode || !targetNode) return null;

  let label, isAdd;

  switch (sourceNode.type) {
    case NodeTypeEnum.BaseNode: {
      isAdd = targetNode.type !== NodeTypeEnum.AddNode;
      label = null;
      break;
    }
    case NodeTypeEnum.IfElse: {
      label =
        sourceNode.data.metadata.true === targetNode.id ? 'True' : 'False';
      isAdd = targetNode.type !== NodeTypeEnum.AddNode;

      break;
    }
    case NodeTypeEnum.IfElseV2: {
      isAdd = false;
      label =
        sourceNode.data.metadata.true === targetNode.id ? 'True' : 'False';
      break;
    }
    case NodeTypeEnum.SwitchNode: {
      const cases = sourceNode.data.metadata.cases;
      for (const caseDetail of cases) {
        if (caseDetail.nodeId === targetNode.id) {
          label = caseDetail.key;
          break;
        }
      }

      label = label || 'label';
      if (targetNode.type === NodeTypeEnum.AddCaseNode) {
        label = null;
      }
      if (sourceNode.data.type === 'SWITCH_CASE_V2') {
        isAdd = false;
      } else {
        isAdd =
          targetNode.type !== NodeTypeEnum.AddNode &&
          targetNode.type !== NodeTypeEnum.AddCaseNode;
      }

      break;
    }
    case NodeTypeEnum.LoopNode: {
      isAdd = targetNode.type !== NodeTypeEnum.AddNode;
      label = null;
      break;
    }
    case NodeTypeEnum.ExecutorNode: {
      isAdd = true;
      label = null;
    }
  }

  const reactFlowEdge: Edge = {
    id: edge.id,
    source: sourceNode.id,
    target: targetNode.id,
    type: 'custom',
    data: {
      isAdd,
      label,
    },
  };
  return reactFlowEdge;
}

export function setMetadataOfIfNode(
  ifNodeId: string | null,
  trueNodeId: string | null,
  falseNodeId: string | null
) {
  const ifNode = getNodeById(ifNodeId);
  const trueNode = getNodeById(trueNodeId);
  const falseNode = getNodeById(falseNodeId);

  if (!ifNode || !trueNode || !falseNode)
    return new Error(`Error in setting Metadata of ifNode`);

  ifNode.data.metadata = { true: trueNode.id, false: falseNode.id };
  trueNode.data.metadata.sourceId = ifNode.id;
  falseNode.data.metadata.sourceId = ifNode.id;
}

/*
update true with newId if true is oldId
update false with newId if false is oldId
*/
export function updateMetaDataOfIfNode(
  nodeId: string | null,
  oldId: string | null,
  newId: string | null
) {
  const ifNode = getNodeById(nodeId);
  const oldNode = getNodeById(oldId);
  const newNode = getNodeById(newId);

  if (!ifNode || !oldNode || !newNode || ifNode.type !== NodeTypeEnum.IfElse)
    return new Error(`Error while updating metadata of ifNode`);

  const metadata = ifNode.data.metadata;
  if (!metadata)
    return new Error(
      `Metadata don't exist on ifElse nodeId ${nodeId}. Error while updating metadata of ifNode`
    );

  if (metadata.true === oldId) {
    metadata.true = newId;
  } else {
    metadata.false = newId;
  }

  newNode.data.metadata!.sourceId = ifNode.id;
}

export function updateMetadataOfSwitchNode(
  nodeId: string | null,
  oldId: string | null,
  newId: string | null
) {
  const switchNode = getNodeById(nodeId);
  const oldNode = getNodeById(oldId);
  const newNode = getNodeById(newId);

  if (
    !switchNode ||
    !oldNode ||
    !newNode ||
    switchNode.type !== NodeTypeEnum.SwitchNode
  )
    return new Error(`Error while updating metadata of switchNode`);

  const newEdgeId = getEdgeId(switchNode.id, newNode.id);
  if (!newEdgeId) {
    return new Error(
      `Error while updating metadata of switchNode. Add not found between switch node and newNode`
    );
  }

  const metadata = switchNode.data.metadata;
  if (!metadata)
    return new Error(
      `Metadata don't exist on switch nodeId ${nodeId}. Error while updating metadata of ifNode`
    );

  let metadataCases = metadata.cases;
  metadataCases = metadataCases.map((metadataCase: switchNodeMetadataType) => {
    if (metadataCase.nodeId === oldNode.id) {
      metadataCase.nodeId = newNode.id;
      metadataCase.edgeId = newEdgeId;
    }
    return metadataCase;
  });
  switchNode.data.metadata.cases = metadataCases;
}

export function setRootNodeOfFlow(nodeId: string | null) {
  if (!getNodeById(nodeId)) return; //Checks this node exist
  const currentRootId = getCurrentRoot();
  if (currentRootId !== 'root')
    getNodeById(currentRootId)!.data!.metadata!.rootNode = nodeId;
  if (currentRootId === 'root') {
    const { setMainRootNode } = useFlowEngineStore.getState();
    setMainRootNode(nodeId || '1');
  }
}

export function getdeleteNodeFunction(nodeType: NodeTypeEnum) {
  return REACT_NODE_AND_DELETE_FUNCTION_MAPPING[nodeType];
}

const REACT_NODE_AND_DELETE_FUNCTION_MAPPING: Record<
  NodeTypeEnum,
  ((nodeId: string | null) => void) | null
> = {
  [NodeTypeEnum.BaseNode]: deleteBaseNode,
  [NodeTypeEnum.IfElse]: deleteIfNode,
  [NodeTypeEnum.IfElseV2]: deleteIfV2Node,
  [NodeTypeEnum.LoopNode]: deleteLoopNode,
  [NodeTypeEnum.SwitchNode]: deleteSwitchNode,
  [NodeTypeEnum.AddCaseNode]: null,
  [NodeTypeEnum.SwitchV2Node]: null,
  [NodeTypeEnum.RootNode]: null,
  [NodeTypeEnum.GroupNode]: null,
  [NodeTypeEnum.ExecutorNode]: null,
  [NodeTypeEnum.AddNode]: null,
  [NodeTypeEnum.ConcurrentNode]: null,
};

export function createStateValueData() {
  const { allEdges } = useFlowEngineStore.getState();
  /**
   * 1. Create a map of source to target edges
   */
  const edgesMap = new Map();

  /**
   *  2. Loop through all edges and create a map of source to target edges
   *     - edgesMap: { sourceNodeId: [targetNodeId1, targetNodeId2, ...] }
   */
  allEdges.forEach((edge) => {
    const { source, target } = edge;

    const targetEdges = edgesMap.get(source) || [];
    targetEdges.push(target);

    edgesMap.set(source, targetEdges);
  });

  const nodeStateValueObjMap = new Map();

  helper(ROOT_ID, {});
  useFlowEngineStore.setState({ nodeStateValueObjMap });

  function helper(nodeId: string, obj: any) {
    let currentLevel: { id: string; state: any }[] = [
      { id: nodeId, state: obj },
    ];
    let nextLevel: { id: string; state: any }[] = [];

    let currentStateValue = {};

    while (currentLevel.length > 0) {
      currentLevel.forEach((item) => {
        const stateValue = saveStateValue(item.id, item.state);
        const executorStateValue = handleExecutorNodeState(item.id, stateValue);

        currentStateValue = { ...currentStateValue, ...executorStateValue };

        const sourceEdges = edgesMap.get(item.id) || [];
        sourceEdges.forEach((targetNodeId: string) =>
          nextLevel.push({ id: targetNodeId, state: executorStateValue })
        );
      });

      currentLevel = nextLevel;
      nextLevel = [];
    }

    return currentStateValue;
  }

  function saveStateValue(nodeId: string, parentStateValue: any) {
    /**
     * 1. Get the current node by nodeId
     */
    const node = getNodeById(nodeId);
    if (!node) return;

    // Explicit handling for HTTP_TRIGGER node (to resolve values for modelId)
    const httpTriggerReturns: any = [];
    if (node?.data.type === 'HTTP_TRIGGER') {
      node.data.returns?.map((item) => {
        if (item.type === 'MODEL_ID') {
          httpTriggerReturns.push({
            ...item,
            modelId: node.data.values?.[item.key],
          });
        } else {
          httpTriggerReturns.push(item);
        }
      });
    }

    /**
     * 2. Set parent's state value in child node
     */
    nodeStateValueObjMap.set(node.id, parentStateValue);
    const prevStateValue = nodeStateValueObjMap.get(node.id) || {};

    let stateValues: any = {
      nodes: {
        ...(prevStateValue?.nodes ? prevStateValue.nodes : {}),
      },
      variables: {
        ...(prevStateValue?.variables ? prevStateValue.variables : {}),
      },
      secrets: {
        ...(prevStateValue?.secrets ?? {}),
      },
    };

    if (Object.keys(variableTypeNodes).includes(node.data.type)) {
      const { title, itemType } = getTitleAndType(node);
      if (title && itemType) {
        stateValues['variables'][title] = {
          name: title,
          type: itemType,
        };
      }
    } else {
      const nodeName = node.data.nodeName;

      // handing HTTP trigger and rest of the nodes seperately
      if (nodeName && node.data.type === 'HTTP_TRIGGER') {
        stateValues['nodes'][nodeName] = httpTriggerReturns;
      } else {
        const nodeReturnSchema = node.data.returns;
        if (nodeName && nodeReturnSchema) {
          stateValues['nodes'][nodeName] = nodeReturnSchema;
        }
      }
    }

    stateValues = { ...prevStateValue, ...parentStateValue, ...stateValues };

    return stateValues;
  }

  function handleExecutorNodeState(nodeId: string, obj: any) {
    const node = getNodeById(nodeId);
    if (!node || !isFlowNode(nodeId)) return obj;

    const rootNodeId = node.data.metadata.rootNode;
    if (!rootNodeId) return new Error('Unable to find rootNodeId');
    return helper(rootNodeId, obj);
  }
}

function isFlowNode(nodeId: string) {
  // Executor, Loop
  const node = getNodeById(nodeId);
  if (!node) return false;

  return [NodeTypeEnum.ExecutorNode, NodeTypeEnum.LoopNode].includes(node.type);
}

const getTitleAndType = (node: NodeRaw) => {
  let title: string;
  let itemType: string;

  switch (node.data.type) {
    case 'COMPLEX_MATHS_EXPR': {
      // explicit handling for `title`
      title = node.data.values?.['result'];
      itemType = 'NUMBER';
      break;
    }
    case 'SET_VARIABLE': {
      // explicit handling for `itemType`
      title = node.data.values?.['name'];
      itemType = node.data.values?.['type'] || 'OBJECT';
      break;
    }
    default: {
      // default handling
      title = node.data.values?.['name'];
      itemType = variableTypeNodes[node.data.type]?.itemType || 'OBJECT';
      break;
    }
  }

  return { title, itemType };
};

type variableTypeNodesTypes = {
  itemType?: string;
};

const variableTypeNodes: Partial<
  Record<BusinessNodeEnum, variableTypeNodesTypes>
> = {
  ADD_VARIABLE: {
    itemType: 'NUMBER',
  },
  APPEND_TO_ARRAY: {
    itemType: 'ARRAY',
  },
  APPEND_TO_STRING: {
    itemType: 'STRING',
  },
  BUILD_JSON: {
    itemType: 'OBJECT',
  },
  BUILD_MAP: {
    itemType: 'OBJECT',
  },
  COMPLEX_MATHS_EXPR: {
    itemType: 'NUMBER',
  },
  CONCAT_STRINGS: {
    itemType: 'STRING',
  },
  CONTAINS: {
    itemType: 'BOOLEAN',
  },
  DECREMENT_VARIABLE: {
    itemType: 'NUMBER',
  },
  DIVIDE_VARIABLE: {
    itemType: 'NUMBER',
  },
  EMPTY_ARRAY: {
    itemType: 'BOOLEAN',
  },
  INCREMENT_VARIABLE: {
    itemType: 'NUMBER',
  },
  LENGTH_ARRAY: {
    itemType: 'NUMBER',
  },
  MERGE_JSON: {
    itemType: 'OBJECT',
  },
  MULTIPLY_VARIABLE: {
    itemType: 'NUMBER',
  },
  REVERSE_ARRAY: {
    itemType: 'ARRAY',
  },
  SET_VARIABLE: {},
  SLICE_STRING: {
    itemType: 'STRING',
  },
  SUBTRACT_VARIABLE: {
    itemType: 'NUMBER',
  },
  TO_LOWER: {
    itemType: 'STRING',
  },
  TO_UPPER: {
    itemType: 'STRING',
  },
  TRIM_STRING: {
    itemType: 'STRING',
  },
  ARRAY_ITEM_AT: {
    itemType: 'ANY',
  },
};
