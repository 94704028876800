export enum FieldTypeEnum {
  BOOLEAN = 'BOOLEAN',
  INPUT = 'INPUT',
  INPUT_NUMBER = 'INPUT_NUMBER',
  INPUT_URL = 'INPUT_URL',
  SELECT = 'SELECT',
  MULTI_SELECT = 'MULTI_SELECT',
  MULTI_SELECT_SEARCHABLE = 'MULTI_SELECT_SEARCHABLE',
  SELECT_SEARCHABLE = 'SELECT_SEARCHABLE',
  STATE_VALUE = 'STATE_VALUE',
  LIST_INPUT = 'LIST_INPUT',
  LIST_INPUT_INPUT = 'LIST_INPUT_INPUT',
  LIST_INPUT_SELECT = 'LIST_INPUT_SELECT',
  LIST_INPUT_SELECT_SEARCHABLE = 'LIST_INPUT_SELECT_SEARCHABLE',
  JSON_CODE_SNIPPET = 'JSON_CODE_SNIPPET',
  QUERY_MODEL = 'QUERY_MODEL',
  LIST_STATE_VALUE = 'LIST_STATE_VALUE',
  DYNAMIC_FIELDS = 'DYNAMIC_FIELDS',
}

export enum BusinessNodeEnum {
  'HTTP_TRIGGER' = 'HTTP_TRIGGER',
  'HTTP_RESPONSE' = 'HTTP_RESPONSE',
  'FUNCTION_RESPONSE' = 'FUNCTION_RESPONSE',
  'ROOT_NODE' = 'ROOT_NODE',
  'ADD_CASE_NODE' = 'ADD_CASE_NODE',
  'ACTION' = 'ACTION',
  'DB' = 'DB',
  'DEBUG' = 'DEBUG',
  'START' = 'START',
  'STOP' = 'STOP',
  'CUSTOM_QUERY_RECORDS' = 'CUSTOM_QUERY_RECORDS',
  'DELETE_MULTIPLE_RECORDS' = 'DELETE_MULTIPLE_RECORDS',
  'DELETE_RECORD' = 'DELETE_RECORD',
  'FETCH_RECORD' = 'FETCH_RECORD',
  'FIND_MANY' = 'FIND_MANY',
  'FIND_ONE' = 'FIND_ONE',
  'INSERT_MULTIPLE_RECORDS' = 'INSERT_MULTIPLE_RECORDS',
  'INSERT_RECORD' = 'INSERT_RECORD',
  'LIST_RECORDS' = 'LIST_RECORDS',
  'UPDATE_MULTIPLE_RECORDS' = 'UPDATE_MULTIPLE_RECORDS',
  'UPDATE_RECORD' = 'UPDATE_RECORD',
  'UPDATE_ONE_RECORD' = 'UPDATE_ONE_RECORD',
  'SET_VARIABLE' = 'SET_VARIABLE',
  'BUILD_JSON' = 'BUILD_JSON',
  'BUILD_MAP' = 'BUILD_MAP',
  'SWITCH_CASE' = 'SWITCH_CASE',
  'IF_ELSE' = 'IF_ELSE',
  'EXECUTOR_NODE' = 'EXECUTOR_NODE',
  'ADD_NODE' = 'ADD_NODE',
  'ADD_VARIABLE' = 'ADD_VARIABLE',
  'APPEND_TO_STRING' = 'APPEND_TO_STRING',
  'APPEND_TO_ARRAY' = 'APPEND_TO_ARRAY',
  'COMPLEX_MATHS_EXPR' = 'COMPLEX_MATHS_EXPR',
  'CONCAT_STRINGS' = 'CONCAT_STRINGS',
  'CONTAINS' = 'CONTAINS',
  'DECREMENT_VARIABLE' = 'DECREMENT_VARIABLE',
  'DIVIDE_VARIABLE' = 'DIVIDE_VARIABLE',
  'EMPTY_ARRAY' = 'EMPTY_ARRAY',
  'FOR_LOOP' = 'FOR_LOOP',
  'INCREMENT_VARIABLE' = 'INCREMENT_VARIABLE',
  'LENGTH_ARRAY' = 'LENGTH_ARRAY',
  'MERGE_JSON' = 'MERGE_JSON',
  'MULTIPLY_VARIABLE' = 'MULTIPLY_VARIABLE',
  'REVERSE_ARRAY' = 'REVERSE_ARRAY',
  'SLICE_STRING' = 'SLICE_STRING',
  'SUBTRACT_VARIABLE' = 'SUBTRACT_VARIABLE',
  'TO_LOWER' = 'TO_LOWER',
  'TO_UPPER' = 'TO_UPPER',
  'TRIM_STRING' = 'TRIM_STRING',
  'IF_ELSE_V2' = 'IF_ELSE_V2',
  'SWITCH_CASE_V2' = 'SWITCH_CASE_V2',
  'WHILE_LOOP' = 'WHILE_LOOP',
  'ARRAY_ITEM_AT' = 'ARRAY_ITEM_AT',
  'EXISTS_NODE' = 'EXISTS_NODE',
  'CURRENT_DATETIME' = 'CURRENT_DATETIME',
  'API_CALL' = 'API_CALL',
  'GET_PRESIGNED_URL' = 'GET_PRESIGNED_URL',
  'POST_PRESIGNED_URL' = 'POST_PRESIGNED_URL',
  'STRING_OPERATIONS' = 'STRING_OPERATIONS',
  'SPLIT_STRING' = 'SPLIT_STRING',
  'OBJECT_TO_STRING' = 'OBJECT_TO_STRING',
  'LENGTH_STRING' = 'LENGTH_STRING',
  'CONVERT_TO_STRING' = 'CONVERT_TO_STRING',
  'EXECUTE_FUNCTION' = 'EXECUTE_FUNCTION',
  'ARRAY_SORT' = 'ARRAY_SORT',
  'SQS_FIRE_EVENTS' = 'SQS_FIRE_EVENTS',
  'SES_SEND_EMAIL' = 'SES_SEND_EMAIL',
  'SNS_SEND_SMS' = 'SNS_SEND_SMS',
  'ARRAY_EXTEND' = 'ARRAY_EXTEND',
  'DELETE_STORAGE_OBJECTS' = 'DELETE_STORAGE_OBJECTS',
  'HASH' = 'HASH',
  'BASE64_ENCODE' = 'BASE64_ENCODE',
  'BASE64_DECODE' = 'BASE64_DECODE',
  'SNS_SEND_PUSH_NOTIFICATION' = 'SNS_SEND_PUSH_NOTIFICATION',
  'STRING_TO_OBJECT' = 'STRING_TO_OBJECT',
}

export type Schema = Array<{
  displayName: string;
  fields: Array<{
    key: string;
    displayName: string;
    immutable: boolean;
    values?: string[];
    resolver?: string;
    type: FieldTypeEnum;
    dependsOn?: {
      key: string;
      value: string[];
    };
    placeholder?: string;
  }>;
}>;

export type Values = Record<string, any>;

export interface BusinessNodeProps {
  type: BusinessNodeEnum;
  displayName: string;
  schema: Schema;
  values: Values;
  nodeName: string;
  metadata: any;
  returns: Array<{
    key: string;
    displayName: string;
    type: 'OBJECT' | 'MODEL_ID' | 'STRING' | 'NUMBER' | 'URL_PARAMS';
  }>;
}
